import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#03376b",
        background: "#D5D7DD",
        secondary: "#03376b",
        tertiary: "#808080",
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
});
