import axios from "axios";
export const GetThemeDetails = {
  data: () => ({}),
  mounted() {},
  methods: {
    async GetThemeDetailsMethod(theme_path) {
      try {
        var data = {
          theme_path,
        };
        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://u2jbv2odgg.execute-api.ap-south-1.amazonaws.com/prod/getthemedetails",
          headers: {
            "x-api-key": "uLQbd9Q8F94ubAWmNcJzt7rTtphZNqQm9m5FLRG7",
          },
          data: data,
        };
        let result = await axios(config);
        return result;
      } catch (error) {
        return {
          status: error.response.status,
          statusText: "FAILURE",
          error: error.response.data.Model,
        };
      }
    },
  },
};
