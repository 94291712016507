export const GetOrganizationLogo = {
  data: () => ({}),
  mounted() {},
  methods: {
    GetOrganizationLogoMethod() {
      if (!this.themeLoaded) return require("@/assets/New_XcelAudit_logo_Blk.png");
      else return this.theme_details.theme_logo;
    },
  },
};
