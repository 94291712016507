import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_7jXvSYbrr",
    region: "ap-south-1",
    userPoolWebClientId: "3mdpeqd1mcu233va075jeesdek",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint:
      "https://7pjakt4pmfamjg37tq2frsdlaq.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
