<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-app-bar app dense clipped-left color="white" class="elevation-1">
      <a href="https://xcelaudit.com"><v-img contain max-width="150px" :src="GetOrganizationLogoMethod()" ></v-img></a>    
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-app>
      <v-main>
        <v-card class="elevation-0 overflow-auto" tile>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="12">
                <v-card class="elevation-0">
                  <v-row no-gutters>
                    <v-col cols="12" xs="12" sm="12" md="8">
                      <v-card :height="windowHeight" color="primary" class="elevation-0">
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col>
                              <div align="center" class="FontSizeVariant6 white--text">{{ current_header }}</div>
                              <div align="center" class="my-6">
                                <v-img max-width="180px" v-if="current_header == 'Step by step work flow'" src="@/assets/organization.gif"></v-img>
                                <v-img max-width="180px" v-if="current_header == 'Step by step across all segments from pre planning till closure'" src="@/assets/checklist.gif"></v-img>
                                <v-img max-width="180px" v-if="current_header == 'Templates and work papers for structured work environment'" src="@/assets/worklist.gif"></v-img>
                                <v-img max-width="180px" v-if="current_header == 'Seemless integration of queries and resolutions'" src="@/assets/opinion.gif"></v-img>
                                <v-img max-width="180px" v-if="current_header == 'Real time updation of all legislative requirements.'" src="@/assets/question.gif"></v-img>
                                <div class="FontSizeVariant8 white--text py-6">Make your audit more efficient and perfect and in line with the requirements of all the regulators</div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" align="center">
                      <v-card class="elevation-0 mt-16 py-8" max-width="400">
                        <v-card-title class="pb-4 pt-1 mt-0">
                          <v-row class="text-center">
                            <v-col cols="12" class="py-0" align="center">
                              <a href="https://xcelaudit.com">   <v-img contain max-width="150px" :src="GetOrganizationLogoMethod()"></v-img></a>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <div v-if="currentTitle != 'Change Temporary Password'" class="mt-5">
                                <h3 class="dark_primary--text font-weight-regular">
                                  {{ currentTitle }}
                                </h3>
                              </div>
                              <div v-else class="mt-5">
                                <h3 class="dark_primary--text font-weight-regular">
                                  {{ currentTitle }}
                                </h3>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          <v-window v-model="step" class="px-3">
                            <v-window-item :value="1">
                              <v-card-text>
                                <v-form ref="form" lazy-validation class="mt-2">
                                  <v-text-field
                                    outlined
                                    dense
                                    class="field_height field_label_size FontSize"
                                    label="Email ID *"
                                    prepend-inner-icon="mdi-account"
                                    :rules="[(v) => !!v || 'Email ID is required']"
                                    v-model="Login.EmailID"
                                  ></v-text-field>
                                  <v-text-field
                                    outlined
                                    dense
                                    label="Password *"
                                    :type="isPwd ? 'text' : 'password'"
                                    @click:append="isPwd = !isPwd"
                                    required
                                    class="field_height field_label_size FontSize"
                                    :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                    prepend-inner-icon="mdi-lock"
                                    :rules="[(v) => !!v || 'Password is required']"
                                    v-model="Login.Password"
                                  ></v-text-field>
                                </v-form>
                              </v-card-text>
                              <v-card-actions class="py-0 mt-n4">
                                <v-checkbox dense class="my-0 py-0 ml-1 FontSize" v-model="rememberme" label="Remember Me" :disabled="Login.EmailID === '' && Login.Password === ''" />
                                <v-spacer></v-spacer>
                                <v-btn small text class="FontSize mb-4" color="primary text-capitalize fontStyle" @click="step = 3">Forgot Password ?</v-btn>
                              </v-card-actions>
                              <v-card-actions class="pt-0 pb-2">
                                <v-btn block :loading="loading" tile color="secondary" @click="ValidateSignUpForm()">Login</v-btn>
                              </v-card-actions>
                            </v-window-item>
                            <v-window-item :value="2">
                              <v-card-text>
                                <v-form ref="formCP" lazy-validation>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="field_height field_label_size FontSize"
                                    label="Enter New Password *"
                                    :type="isPwdNew ? 'text' : 'password'"
                                    @click:append="isPwdNew = !isPwdNew"
                                    required
                                    :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                                    prepend-inner-icon="mdi-lock"
                                    :rules="[(v) => !!v || 'Password is required']"
                                    v-model="Password.NewPassword"
                                  ></v-text-field>
                                  <v-text-field
                                    class="field_height field_label_size FontSize"
                                    outlined
                                    dense
                                    label="Confirm New Password *"
                                    :type="isPwdConfirm ? 'text' : 'password'"
                                    @click:append="isPwdConfirm = !isPwdConfirm"
                                    required
                                    :append-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                    prepend-inner-icon="mdi-lock"
                                    :rules="[(v) => !!v || 'Password is required']"
                                    v-model="Password.ConfirmPassword"
                                  ></v-text-field>
                                </v-form>
                              </v-card-text>
                              <v-card-actions class="pt-0 pb-8 px-4">
                                <v-btn block :loading="loading" tile color="secondary" @click="ValidateConfirmPasswordForm()">Login</v-btn>
                              </v-card-actions>
                            </v-window-item>
                            <v-window-item :value="3">
                              <v-card-text>
                                <v-form ref="formOTP">
                                  <div class="text-center">Please Enter The Registered Email</div>
                                  <v-text-field dense outlined label="Email" v-model="OTPEmail" :rules="[(v) => !!v || 'Email ID is required']" class="mt-4"> </v-text-field>
                                  <div class="FontSize text-center">*Please enter the e-mail used for XcelAudit login/registration</div>
                                </v-form>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn text color="primary fontStyle" small @click="step = 1">Back To Sign In</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn tile color="secondary" small @click="ValidateOTP()">Next</v-btn>
                              </v-card-actions>
                            </v-window-item>
                            <v-window-item :value="4">
                              <v-card-text>
                                <v-form ref="formConfirmOTP">
                                  <div class="text-center">Enter the XcelAudit Activation Code</div>
                                  <v-text-field dense outlined label="Code *" v-model="ConfirmOTP" :rules="[(v) => !!v || 'Code is required']" class="mt-4"> </v-text-field>
                                  <v-text-field
                                    dense
                                    outlined
                                    label="Enter New Password *"
                                    v-model="ConfirmNewPassword"
                                    :rules="[(v) => !!v || 'Password is required']"
                                    class="field_height field_label_size FontSize"
                                    :type="isPwdConfirmCode ? 'text' : 'password'"
                                    @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                                    required
                                    :append-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                                    prepend-inner-icon="mdi-lock"
                                  >
                                  </v-text-field>
                                  <v-text-field
                                    dense
                                    outlined
                                    label="Re-enter New Password *"
                                    v-model="ReenterNewPassword"
                                    :rules="[(v) => !!v || 'Password is required']"
                                    class="field_height field_label_size FontSize"
                                    :type="isPwdConfirmCode ? 'text' : 'password'"
                                    @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                                    required
                                    :append-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                                    prepend-inner-icon="mdi-lock"
                                  >
                                  </v-text-field>
                                  <div class="FontSize text-center">Enter the XcelAudit Activation Code sent to your registered Email.</div>
                                </v-form>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn text color="primary" small @click="step = 1">Back To Sign In</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn text color="secondary" small @click="ValidateEnteredOTP()">Next</v-btn>
                              </v-card-actions>
                            </v-window-item>
                          </v-window>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";

import { GetThemeDetails } from "@/mixins/RESTAPIs/GetThemeDetails.js";
import { GetOrganizationLogo } from "@/mixins/Extras/GetOrganizationLogo.js";
export default {
  components: {
    Snackbar,
  },
  mixins: [GetThemeDetails, GetOrganizationLogo],
  data: () => ({
    step: 1,
    height: 0,
    windowHeight: 0,

    OTPEmail: "",
    ConfirmOTP: "",
    current_header: "",
    ConfirmNewPassword: "",
    ReenterNewPassword: "",

    Login: {
      EmailID: "",
      Password: "",
    },
    isPwd: false,
    loading: false,
    isPwdNew: false,
    rememberme: false,
    themeLoaded: false,
    isPwdConfirm: false,
    isPwdConfirmCode: false,

    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    theme_details: {},
    SnackBarComponent: {},

    headerArrays: [
      "Step by step across all segments from pre planning till closure",
      "Templates and work papers for structured work environment",
      "Real time updation of all legislative requirements.",
      "Seemless integration of queries and resolutions",
      "Step by step work flow",
    ],
  }),
  watch: {
    rememberme(val) {
      if (val == true) {
        localStorage.setItem("currentuseremail", JSON.stringify(this.Login));
      } else if (val == false) {
        delete localStorage.currentuseremail;
      }
    },
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Sign In";
        case 2:
          return "Change Temporary Password";
        case 3:
          return "Forgot Password ?";
        case 4:
          return "Forgot Password ?";
        default:
          return "Account created";
      }
    },
  },
  async mounted() {
    this.themeLoaded = false;
    this.$store.commit("SET_THEME_LOADED", false);
    this.CheckRememberMe();
    this.windowHeight = window.innerHeight - 100;
    this.runHeaderMethod();
    let currentURL = window.location.href;
    let urlresult = this.extractCompanyName(currentURL);
    let result = await this.GetThemeDetailsMethod(urlresult);
    if (result.status == 200 && result.data.items) {
      let raw_theme_details = result.data.items[0];
      this.theme_details = {};
      for (let key1 in raw_theme_details) {
        for (let key2 in raw_theme_details[key1]) {
          this.theme_details[key1] = raw_theme_details[key1][key2];
        }
      }
      this.$store.commit("SET_THEME_DETAILS", this.theme_details);
      this.$store.commit("SET_THEME_LOADED", true);
      this.$vuetify.theme.themes.light.primary = this.theme_details.primary_theme_color_code.slice(0, -2);
      this.$vuetify.theme.themes.light.secondary = this.theme_details.secondary_theme_color_code.slice(0, -2);
      this.themeLoaded = true;
    }
  },
  methods: {
    extractCompanyName(url) {
      const regex = /^https?:\/\/([^./]+)\./;
      const match = url.match(regex);
      if (match && match[1]) {
        return match[1];
      } else {
        return "DEFAULT";
      }
    },
    runHeaderMethod() {
      this.count = 0;
      this.current_header = this.headerArrays[this.count];
      setInterval(() => {
        if (this.count == this.headerArrays.length - 1) {
          this.count = 0;
        } else {
          this.count += 1;
        }
        this.current_header = this.headerArrays[this.count];
      }, 5000);
    },
    CheckRememberMe() {
      if (localStorage.getItem("currentuseremail") != null) {
        this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
        this.rememberme = true;
      }
    },
    ConfirmOTPMethod() {
      Auth.forgotPasswordSubmit(this.OTPEmail, this.ConfirmOTP, this.ConfirmNewPassword)
        .then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.step = 1;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    ValidateEnteredOTP() {
      if (this.$refs.formConfirmOTP.validate()) {
        if (this.ReenterNewPassword == this.ConfirmNewPassword) {
          this.ConfirmOTPMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password Does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    ValidateOTP() {
      if (this.$refs.formOTP.validate()) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
        };
      }
    },
    SendOTPEmail() {
      Auth.forgotPassword(this.OTPEmail)
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
          };
          this.step = 4;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    async ConfirmPassword() {
      try {
        this.loading = true;
        const result = await Auth.completeNewPassword(this.user, this.Password.ConfirmPassword)
          .then((user) => {
            return user;
          })
          .catch((e) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: e,
            };
            return e;
          });
        if (result.username) {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    async SignIn() {
      try {
        this.loading = true;
        this.user = await Auth.signIn(this.Login.EmailID.toLowerCase().trim(), this.Login.Password.trim());
        if (this.user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.step = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID.toLowerCase());
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID.toLowerCase());
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.ActivateMethod();
          this.loading = false;
        }
      } catch (error) {
        console.log("error", error);
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    ActivateMethod() {
      setTimeout(() => this.$router.push("/LandingPage"), 500);
    },
    ValidateConfirmPasswordForm() {
      if (this.$refs.formCP.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ConfirmPassword();
        } else {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    ValidateSignUpForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
      // this.$router.push("/LandingPage");
    },
  },
};
</script>

<style scoped>
.v-application .rounded-b1-x1 {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-x1 {
  border-bottom-right-radius: 300px !important;
}
</style>
